<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card w-100">
        <div class="card-body position-relative p-4">
          <h5 class="card-title fw-semibold mb-4">Sucursales</h5>
          <div class="reload_btn" @click="getData">
            <i class="ti ti-reload" :class="{animated_icon: loading}"/>
          </div>
          <div class="table-responsive">
            <table class="table text-nowrap mb-0 align-middle">
              <thead class="text-dark fs-4">
              <tr>
                <th width="8%" class="border-bottom-0">
                  <h6 class="fw-semibold mb-0">Id</h6>
                </th>
                <th  width="30%" class="border-bottom-0">
                  <h6 class="fw-semibold mb-0">Nombre</h6>
                </th>
                <th  width="25%" class="border-bottom-0">
                  <h6 class="fw-semibold mb-0">Gerente</h6>
                </th>
                <th  width="15%" class="border-bottom-0">
                  <h6 class="fw-semibold mb-0">Estatus</h6>
                </th>
                <th  width="15%" class="border-bottom-0">
                  <h6 class="fw-semibold mb-0">Total</h6>
                </th>
                <th  width="10%" class="border-bottom-0">
                  <h6 class="fw-semibold mb-0">Acciones</h6>
                </th>
              </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="5" class="text-center">
                    <div class="loader loader_center"/>
                  </td>
                </tr>
                <template v-else>
                  <tr v-for="(back_office, i) in back_offices" :key="'key'+i">
                    <td class="border-bottom-0"><h6 class="fw-semibold mb-0">{{back_office.id}}</h6></td>
                    <td class="border-bottom-0">
                      <h6 class="fw-semibold mb-1">{{back_office.name}}</h6>
                      <span class="fw-normal">{{back_office.place}}</span>
                    </td>
                    <td class="border-bottom-0">
                      <p class="mb-0 fw-normal">{{back_office.manager}}</p>
                    </td>
                    <td class="border-bottom-0">
                      <div class="d-flex align-items-center gap-2">
                        <span class="badge bg-primary rounded-3 fw-semibold">Activa</span>
                      </div>
                    </td>
                    <td class="border-bottom-0">
                      <h6 class="fw-semibold mb-0 fs-4">{{back_office.surveys_count}}</h6>
                    </td>
                    <td class="border-bottom-0">
                      <button type="button" class="btn btn-primary m-1" @click="openDetail(back_office.id)">Detalle</button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore()
const router = useRouter()

const back_offices = ref(null);
const loading = ref(false);

const openDetail = (back_office_detail)=>{
  router.push({name: 'back_office_detail', params:{
      back_office_id: back_office_detail
    }})
}

const getData = ()=>{
  loading.value = true;
  store.dispatch('user/getBackOffice').then(data=>{
    back_offices.value = data;
    loading.value = false;
  })
}


onMounted(()=>{
  getData()
})

</script>


<style lang="scss">
.reload_btn{
  background-image: linear-gradient(310deg, #03A9F4, #009688);
  color: white;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  top: 15px;

  &:hover{
    cursor: pointer;
    background-image: linear-gradient(310deg, #0378a3, #009688);
  }

  i{
    font-size: 18px;

    &.animated_icon{
      animation: rotation 1s infinite linear;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

  }
}


</style>
