<script setup>

import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";

const router = useRouter()
const route = useRoute()

const setRoute = (route)=>{
  router.push({name: route})
}

const routerName = computed(()=>{
  return route.meta.routerName
})


const logout = ()=>{
  localStorage.removeItem('drd_login')
  localStorage.removeItem('rt')
  localStorage.removeItem('at')
  window.location.reload()
}


</script>

<template>
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div>
      <div class="brand-logo d-flex align-items-center justify-content-between">
        <a class="text-nowrap text-center logo-img m-auto">
          <img src="@/assets/images/logo.png" width="160" alt="" />
        </a>
        <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
          <i class="ti ti-x fs-8"></i>
        </div>
      </div>
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
        <ul id="sidebarnav">
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Inicio</span>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" :class="{'active': routerName === 'back_office'}" @click="setRoute('back_office')" aria-expanded="false">
                <span>
                  <i class="ti ti-layout-dashboard"></i>
                </span>
              <span class="hide-menu">Sucursales</span>
            </a>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Tableros diarios</span>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link"  :class="{'active': routerName === 'surveys'}"  @click="setRoute('surveys')" aria-expanded="false">
                <span>
                  <i class="ti ti-mood-happy"></i>
                </span>
              <span class="hide-menu">Encuestas</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" :class="{'active': routerName === 'comments'}"  @click="setRoute('comments')" aria-expanded="false">
                <span>
                  <i class="ti ti-article"></i>
                </span>
              <span class="hide-menu">Comentarios</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" :class="{'active': routerName === 'alerts'}"  @click="setRoute('alerts')"  aria-expanded="false">
                <span>
                  <i class="ti ti-alert-circle"></i>
                </span>
              <span class="hide-menu">Alertas</span>
            </a>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">REPORTES</span>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link"  :class="{'active': routerName === 'reports'}"  @click="setRoute('reports')"  aria-expanded="false">
                <span>
                  <i class="ti ti-download"></i>
                </span>
              <span class="hide-menu">Descargas</span>
            </a>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Configuracion</span>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" href="./icon-tabler.html" aria-expanded="false">
                <span>
                  <i class="ti ti-mood-happy"></i>
                </span>
              <span class="hide-menu">Perfil</span>
            </a>
          </li>
          <li class="sidebar-item" >
            <a class="sidebar-link logout" @click="logout"  aria-expanded="false">
                <span>
                  <i class="ti ti-logout"></i>
                </span>
              <span class="hide-menu">Cerrar sesion</span>
            </a>
          </li>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
</template>

<style scoped lang="scss">
.sidebar-link.logout{
  background: white;
  color: red!important;

  &:hover{
    background: white!important;
    color: red!important;
  }

}
</style>
