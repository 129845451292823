<template>
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
       data-sidebar-position="fixed" data-header-position="fixed">
    <side-bar/>
    <div class="body-wrapper">
      <!--  Header Start -->
      <top-bar/>
      <!--  Header End -->
      <div class="container-fluid">
        <!--  Row 1 -->
        <div class="">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

</style>
<script setup>
import SideBar from "@/components/layout/SideBar.vue";
import TopBar from "@/components/layout/TopBar.vue";

import { onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore()

onMounted(()=>{
  store.dispatch('getCatalog')
})

</script>
