import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/BackOfficesView.vue'

const routes = [
  {
    path: '/sing_in',
    name: 'sing_in',
    meta: {loginFree: true, layout: 'auth_layout'},
    component: () => import(/* webpackChunkName: "about" */ '../views/SingInView.vue')
  },
  {
    path: '/',
    name: 'back_office',
    meta: { routerName:'back_office', layout: 'app_layout' },
    component: () => import(/* webpackChunkName: "about" */ '../views/BackOfficesView.vue')
  },
  {
    path: '/back_office/:back_office_id',
    name: 'back_office_detail',
    meta: { routerName:'back_office' , layout: 'app_layout'},
    component: () => import(/* webpackChunkName: "about" */ '../views/BackOfficeDetail.vue')
  },
  {
    path: '/back_office/:back_office_id/surveys',
    name: 'back_office_detail_surveys',
    meta: { routerName:'back_office' , layout: 'app_layout'},
    component: () => import(/* webpackChunkName: "about" */ '../views/BackOfficeDetailSurveys.vue')
  },
  {
    path: '/back_office/:back_office_id/comments',
    name: 'back_office_detail_comments',
    meta: { routerName:'back_office', layout: 'app_layout' },
    component: () => import(/* webpackChunkName: "about" */ '../views/BackOfficeDetailComments.vue')
  },
  {
    path: '/back_office/:back_office_id/alerts',
    name: 'back_office_detail_alerts',
    meta: { routerName:'back_office', layout: 'app_layout' },
    component: () => import(/* webpackChunkName: "about" */ '../views/BackOfficeDetailAlerts.vue')
  },
  {
    path: '/surveys',
    name: 'surveys',
    meta: { routerName:'surveys', layout: 'app_layout' },
    component: () => import(/* webpackChunkName: "about" */ '../views/SurveysView.vue')
  },
  {
    path: '/comments',
    name: 'comments',
    meta: { routerName:'comments', layout: 'app_layout' },
    component: () => import(/* webpackChunkName: "about" */ '../views/CommentsView.vue')
  },
  {
    path: '/alerts',
    name: 'alerts',
    meta: { routerName:'alerts', layout: 'app_layout' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AlertsView.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    meta: { routerName:'reports', layout: 'app_layout' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('drd_login')

  if (!to.meta.loginFree && !isAuthenticated) {
    next({
      name: 'sing_in',
    })
  } else {
    next()
  }
});

export default router
