import { createStore } from 'vuex'
import users from './modules/user'
import axios from "@/api/client";
export default createStore({
  state: {
    back_offices: []
  },
  getters: {
  },
  mutations: {
    setCatalog(state, value){
      state.back_offices = value
    }
  },
  actions: {
    getCatalog({commit}, query){
      return new Promise((resolve, reject) => {
        axios.get('/catalog').then(response=>{
          commit('setCatalog', response.data)
        }).catch(err=>reject(err))
      });
    },
  },
  modules: {
    user: users
  }
})
