import axios from "../../api/client";
import axiosInstance from "../../api/client";


// initial state
export default  {
    namespaced: true,
    state: () => ({

    }),
    mutations: {

    },
    actions:{
        getSurveys({commit}, query){
            return new Promise((resolve, reject) => {
                axios.get('/surveys').then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getComments({commit}, query){
            return new Promise((resolve, reject) => {
                axios.get('/comments').then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getAlerts({commit}, query){
            return new Promise((resolve, reject) => {
                axios.get('/alerts').then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getBackOffice({commit}, query){
            return new Promise((resolve, reject) => {
                axios.get('/back_office').then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getBackOfficeDetail({commit}, id){
            return new Promise((resolve, reject) => {
                axios.get('/back_office/'+id).then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getBackOfficeDetailSurveys({commit}, {id, page}){
            return new Promise((resolve, reject) => {
                axios.get('/back_office/'+id+'/surveys?page='+page).then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getBackOfficeDetailComments({commit}, {id, page}){
            return new Promise((resolve, reject) => {
                axios.get('/back_office/'+id+'/comments?page='+page).then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getBackOfficeDetailAlerts({commit}, {id, page}){
            return new Promise((resolve, reject) => {
                axios.get('/back_office/'+id+'/alerts?page='+page).then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
        getBackOfficeDetailSurveysQuestions({commit}, id){
            return new Promise((resolve, reject) => {
                axios.get('/survey/'+id).then(response=>{
                        resolve(response.data)
                }).catch(err=>reject(err))
            });
        },
    },
    getters: {

    }
}
