<template>
  <auth-layout v-if="layout==='auth_layout'"/>
  <app-layout v-else-if="layout==='app_layout'"/>
</template>

<style lang="scss">

</style>
<script setup>

import AppLayout from "@/components/layout/AppLayout.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import AuthLayout from "@/components/layout/AuthLayout.vue";


const route = useRoute()

const layout = computed(()=>{
  return route.meta.layout
})

</script>
